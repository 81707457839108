/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import CountUp from 'react-countup';
import { notificationMessages } from '../../defineMessages';
import Spinner from '../Spinner';
import s from './Loading.scss';

const Loading = ({ loadingMessage, estimatedLoadingTime }) => {
  const [estimationDone, setEstimationDone] = useState(false);

  return (
    <div className={s.root}>
      <Spinner />
      {loadingMessage && (
        <p className={s.loadingMessage}>
          <FormattedMessage {...loadingMessage} />
          ...
        </p>
      )}
      {estimatedLoadingTime && (
        <>
          <CountUp
            className={s.loader__percentage}
            start={1}
            end={99}
            duration={estimatedLoadingTime}
            useEasing
            // eslint-disable-next-line no-return-assign
            easingFn={(t, b, c, d) => c * (t /= d) * t * t + b}
            onEnd={() => setTimeout(() => setEstimationDone(true), 5000)}
            suffix="%"
          />
          {estimationDone && (
            <p className={s.longRequest__text}>
              <FormattedMessage {...notificationMessages.longRequest} />
            </p>
          )}
        </>
      )}
    </div>
  );
};

Loading.propTypes = {
  loadingMessage: PropTypes.shape(),
  estimatedLoadingTime: PropTypes.number,
};

Loading.defaultProps = {
  loadingMessage: undefined,
  estimatedLoadingTime: undefined,
};

export default compose(injectIntl, withStyles(s))(Loading);
