// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.scss");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "._2VuHt {\n  width: 100%;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: var(--footer-height);\n}\n\n.QOUG7 {\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  max-width: var(--max-content-width);\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_2VuHt",
	"container": "QOUG7"
};
module.exports = exports;
