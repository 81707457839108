export const company = {
  '@context': 'https://schema.org/',
  '@type': 'Corporation',
  name: 'shop.GoGift.com',
  alternateName: [
    'Presentkorttorget.se',
    'Gavekortet.dk',
    'GavekortTorget.no',
    'Presentcard.fi',
  ],
  url: 'https://shop.gogift.com',
  logo: 'https://shop.gogift.com/images/4b49026b29d2e786539200ee85203475.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+46 10 - 138 84 44',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'SE',
      availableLanguage: [
        'en',
        'Swedish',
        'Finnish',
        'Norwegian Bokmal',
        'Norwegian Nynorsk',
        'Norwegian',
        'Danish',
        'German',
        'fr',
      ],
    },
    {
      '@type': 'ContactPoint',
      telephone: '+46 8 243 202',
      contactType: 'sales',
      contactOption: 'TollFree',
      areaServed: 'SE',
      availableLanguage: [
        'en',
        'fr',
        'Danish',
        'Norwegian Bokmal',
        'Norwegian Nynorsk',
        'Norwegian',
        'Finnish',
        'Swedish',
      ],
    },
    {
      '@type': 'ContactPoint',
      telephone: '+45 70 89 00 60',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'DK',
      availableLanguage: [
        'en',
        'Danish',
        'Swedish',
        'Norwegian Bokmal',
        'Norwegian Nynorsk',
        'Norwegian',
        'Finnish',
      ],
    },
  ],
  sameAs: [
    'https://www.facebook.com/GoGiftNO/',
    'https://www.facebook.com/GoGiftDK/',
    'https://sv-se.facebook.com/GoGiftSE/',
    'https://www.presentkorttorget.se',
  ],
};

export default company;
