// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".p99si{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;background-color:#fff;z-index:1}.p99si ._2SEzX{font-weight:600;padding-top:12px}.p99si ._2Eexb{font-weight:600;padding-top:12px}.p99si ._27lPZ{padding-top:12px}\n", ""]);
// Exports
exports.locals = {
	"root": "p99si",
	"loader__percentage": "_2SEzX",
	"longRequest__text": "_2Eexb",
	"loadingMessage": "_27lPZ"
};
module.exports = exports;
